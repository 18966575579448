import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN  from "./locale/en";
import translationAR  from "./locale/ar.json";
import { useSubmit } from "react-router-dom";
const resources = {
  en: {
    translation: translationEN
    
  },
  ar: {
    translation:translationAR 
  }
};

i18n
.use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    resources,
    lng: "ar", 
    interpolation: {
      escapeValue: false 
    },
    reat:{
        useSubmit:false
    }
  });

  export default i18n;