import React, { useEffect, useState } from "react";
import img12 from "../../assets/img/img12.png";
import 'animate.css';
import { useInView } from 'react-intersection-observer';
import logo from "../../assets/img/logo.png";
import exa from "../../assets/img/exa.png";
import { fetchDataSettings } from '../../api/settingsApi/index.js';
import { useTranslation } from "react-i18next";

const ContactAs = () => {
  const { t, i18n } = useTranslation();

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: footerRef, inView: footerInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [dataSource, setDataSource] = useState([]);

  const fetchDataS = async () => {
    try {
      const data = await fetchDataSettings();
      setDataSource(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataS();
  }, []);

  return (
    <div className="flex myClass2 flex-col min-h-screen bg-[#202225] text-white">
      {/* قسم النموذج والصورة */}
      <div className="container mx-auto flex flex-col lg:flex-row items-center rounded-xl p-6 md:mt-20 pt-12 md:pt-24 min-h-[70vh] md:min-h-[auto]">
        {/* قسم النموذج */}
        <div
          ref={formRef}
          className={`w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-12 ${
            formInView ? 'animate__animated animate__fadeInLeft' : ''
          }`}
        >
          <h2 className={`text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 ${
              formInView ? 'animate__animated animate__fadeInUp' : ''
            }`}>
            {t("Contact-us")}
          </h2>

          <form className={`space-y-4 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''
          }`}>
            <input
              type="text"
              placeholder={t("enter_name")}
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none"
            />
            <input
              type="email"
              placeholder={t("enter_email")}
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none"
            />
            <textarea
              placeholder={t("message")}
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none h-32 resize-none"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-black p-3 rounded-lg font-bold hover:bg-yellow-600 transition"
            >
              {t("send")}
            </button>
          </form>

          {/* روابط التواصل الاجتماعي */}
          <div className={`mt-8 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''
          }`}>
            <h3 className="text-lg mb-4"> {t("contact_us_via")}  </h3>
            <div className="flex flex-wrap gap-2">
              <button className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                WhatsApp
              </button>
              <button className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                Telegram
              </button>
              <a href={ dataSource && dataSource.data && dataSource.data[0].facebook}  className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                Facebook
              </a >
            </div>
          </div>

          {/* رقم الهاتف */}
          <div className={`mt-8 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-3s' : ''
          }`}>
            <h3 className="text-lg mb-2">{t("phone_number")} </h3>
            <p className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg">
              {
                dataSource && dataSource.data && dataSource.data[0].phone_number
              }
            </p>
          </div>
        </div>

        {/* قسم الصورة */}
        <div className={`w-full lg:w-1/2 flex justify-center ${
          formInView ? 'animate__animated animate__fadeInRight' : ''
        }`}>
          <img
            className="w-full max-w-lg h-auto rounded-lg shadow-lg hidden md:block"
            src={img12}
            alt="Contact Image"
          />
        </div>
      </div>

      {/* قسم الفوتر */}
      <footer ref={footerRef} className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-6 py-6 bg-[#202225] min-h-[70vh] md:min-h-[auto]">
          {/* قسم الشعار */}
          <div className={`flex flex-col md:flex-row items-center mb-6 lg:mb-0 ${footerInView ? 'animate__animated animate__fadeInLeft' : ''}`}>
  <div className="grid grid-cols-1 md:grid-cols-10 gap-4 items-center w-full">
    {/* شعار الشركة */}
    <div className="flex justify-center md:justify-start md:col-span-1">
      <div className="md:border-l border-gray-600 pl-4">
        <img
          src={logo}
          alt="logo"
          className="w-[60px] md:w-[30px]"
        />
      </div>
    </div>

    {/* اسم الشركة والصورة */}
    <div className="md:col-span-9 w-full text-center md:text-right">
      <p className="mb-2 text-2xl md:text-lg">شركة اكساء العراق</p>
      <img
        src={exa}
        alt="exa"
        className="w-full md:w-[400px] mx-auto md:mx-0"
      />
    </div>
  </div>
</div>


          {/* روابط التنقل */}
          <nav className={`flex hidden md:block flex-row space-y-4 lg:space-y-0 space-x-reverse space-x-6 ${footerInView ? 'animate__animated animate__fadeInRight' : ''}`}>
            <a href="#" className="hover:underline">
            {t("home")}
            </a>
            <a href="#" className="hover:underline">
            {t("About-us")}
            </a>
            <a href="#" className="hover:underline">
            {t("Project")}
            </a>
            <a href="#" className="hover:underline">
            {t("products")}
            </a>
          </nav>
        </footer>
    </div>
  );
};

export default ContactAs;
