import { BASIC_URL } from "../index";

export const fetchDataProjects = async ({ search, paginate, page }) => {
    try {

        const myHeaders = new Headers({
            "Content-Type": "application/json",
        });

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${BASIC_URL}/projects?search=${search}&page=${page}&per_page=${paginate}`, requestOptions);


        const data = await response.json();

        if (!data.success) {
            return {
                data: [],
                loading: false,
                hasErrors: true,
                total: 0,
                message: data.message || "Error fetching projects.",
            };
        }
        const list = data.projects.data.map((item, index) => ({
            key: index,
            index: index + 1,
            id: item.id,
            name_ar: item.name_ar,
            name_en: item.name_en,
            description_ar: item.description_ar,
            description_en: item.description_en,
            area: item.area,
            governorate: item.governorate,
            completion_duration: item.completion_duration,
            number_of_workers: item.number_of_workers,
            created_at: item.created_at,
            images: item.images,
        }));

        return {
            data: list,
            loading: false,
            hasErrors: false,
            total: data.projects.total,
        };
    } catch (error) {
        console.error("Error fetching projects:", error.message);

        if (error.message.includes("Unauthorized")) {
            localStorage.removeItem("token");
            window.location.reload();
        }

        return {
            data: [],
            loading: false,
            hasErrors: true,
            total: 0,
            message: error.message,
        };
    }
};
export const fetchDataProjectById = async ({ id }) => {
    try {
      const myHeaders = new Headers({
        "Content-Type": "application/json",
      });
  
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        credentials: 'include',
      };
  
      // استدعاء API باستخدام fetch لجلب بيانات المشروع بناءً على id
      const response = await fetch(`${BASIC_URL}/project/${id}`, requestOptions);
      const data = await response.json();
  
      // تحقق من نجاح الاستجابة
      if (!data.success) {
        return {
          data: null,
          loading: false,
          hasErrors: true,
          total: 0,
          message: data.message || "Error fetching project details.",
        };
      }
  
      // تنسيق بيانات المشروع
      const project = {
        id: data.project.id,
        name_ar: data.project.name_ar,
        name_en: data.project.name_en,
        description_ar: data.project.description_ar,
        description_en: data.project.description_en,
        area: data.project.area,
        governorate: data.project.governorate,
        completion_duration: data.project.completion_duration,
        number_of_workers: data.project.number_of_workers,
        created_at: data.project.created_at,
        images: data.project.images, // تأكد من وجود الصور
      };
  
      // إرجاع البيانات المنسقة مع حالة النجاح
      return {
        data: project,
        loading: false,
        hasErrors: false,
        total: 1,
      };
    } catch (error) {
      console.error("Error fetching project details:", error.message);
  
      if (error.message.includes("Unauthorized")) {
        localStorage.removeItem("token");
        window.location.reload();
      }
  
      // إرجاع الاستجابة في حالة حدوث خطأ
      return {
        data: null,
        loading: false,
        hasErrors: true,
        total: 0,
        message: error.message,
      };
    }
  };
  