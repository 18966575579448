import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useInView } from "react-intersection-observer";
import "./CustomSwiper.css"; // ملف CSS لتخصيص الأزرار
import icon2 from "../../assets/icons/icon2.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SlideItem = ({ image, title, area, description, statsInView }) => (
  
  <div
    className={`
      animate__animated 
      ${statsInView ? `opacity-100 animate__fadeInRight` : "opacity-0"}
      relative flex flex-col mx-5 group rounded-xl overflow-hidden mt-16 md:mt-0 h-[60vh] md:h-[55vh] transition-all duration-500 ease-in-out
    `}
  >
    {/* الصورة الأساسية */}
    <div className="relative h-[55vh] transition-all duration-700 ease-in-out group-hover:blur-md group-hover:scale-110">
      <img
        className="w-full h-[60vh] md:h-[55vh] object-cover rounded-lg transition-all duration-700 ease-in-out "
        src={image}
        alt={title}
      />
        <img
              src={icon2}
              alt="image1"
              className=" absolute  top-3 left-3 w-8"
            />
      {/* العنوان والمنطقة */}
      <div className="absolute  inset-0 flex items-end mb-10 justify-center transition-opacity duration-500 ease-in-out opacity-100">
        <div className="w-[200px] text-white p-4 text-center bg-black/30 backdrop-blur-md rounded-2xl border border-white">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="text-sm">{area}</p>
        </div>
      </div>
    </div>

    {/* التفاصيل التي تظهر عند التمرير */}
    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white bg-black/60 transition-all duration-700 ease-in-out opacity-0 group-hover:opacity-100">
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-sm mb-4">{area}</p>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

const ProjectSlider = ({ dataSource }) => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });
  const { t, i18n } = useTranslation();

  return (
    <div ref={statsRef} className="md:mx-auto md:mt-10 md:py-10" dir="ltr">
      <div className="flex justify-between items-start md:mb-20 ">
        <div className="flex flex-col">
          <h2 className="text-4xl font-bold">{t("Project")}</h2>
          <hr className="w-[180px] h-[2px] bg-black mt-4 mb-5 md:mb-4" />
        </div>
        <Link to={'project'} className="border border-black p-4 rounded-xl hover:bg-[#1E1E1E] hover:text-white transition-all duration-300 ease-in-out">
        {t("show-more")}
        </Link>
      </div>

      {/* Swiper Slider */}
      <Swiper
        navigation={true} // استخدام أزرار التنقل الافتراضية لـ Swiper
        modules={[Navigation]} // حذف Pagination من الوحدات
        breakpoints={{
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1.25,
            spaceBetween: 2,
          },
        }}
      >
        {dataSource.map((data, index) => (
          <SwiperSlide key={index}>
            <SlideItem
              image={data.images[0].image_url}
              title={ i18n.language === "ar" ? data.name_ar : data.name_en }
              area={` ${t("meter")} ${data.area}`}
              description={i18n.language === "ar" ? data.description_ar :  data.description_en}
              statsInView={statsInView}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProjectSlider;
