import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchDataProjectById } from "../../api/projectsApi/index.js";
import { BsFillGearFill, BsTrash } from "react-icons/bs"; // أيقونات تفاعلية
import { AiOutlineHome } from "react-icons/ai"; // أيقونة العودة
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";

const ProductDetailByIdPage = () => {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState("");
  const [dataSource, setDataSource] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { t, i18n } = useTranslation();

  const fetchData = async (projectId) => {
    try {
      const data = await fetchDataProjectById({ id: projectId });
      setDataSource(data.data);
      setSelectedImage(data.data.images ? data.data.images[0].image_url : "");
    } catch (error) {
      console.log("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <div className="min-h-screen bg-gradient-to-r md:mt-20 from-gray-50 to-gray-100 py-16">
      {/* رأس الصفحة - العنوان الرئيسي */}
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 max-w-5xl mx-auto mb-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-4xl font-bold text-gray-900">
            { i18n.language === "ar" ?  dataSource.name_ar :dataSource.name_en|| `${t("project_details")}`
          }
          </h1>
          <button
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition-all"
            onClick={() => window.history.back()}
          >
            <AiOutlineHome className="mr-2" size={24} />
            {t("back")}

          </button>
        </div>
        <p className="text-gray-600">{i18n.language === "ar" ?  dataSource.description_ar : dataSource.description_en }</p>
      </motion.div>
      {/* معرض الصور */}
      {selectedImage && (
        <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4"> {t("photo_gallery")} </h2>
          <motion.div
            className="w-full h-[400px] bg-gray-200 rounded-lg shadow-lg overflow-hidden mb-6"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={() => setOpenModal(true)} // فتح النافذة المنبثقة عند النقر على الصورة
          >
            <img
              src={selectedImage}
              alt="Selected Project"
              className="w-full h-full object-cover cursor-pointer"
            />
          </motion.div>

          {/* معرض الصور المصغرة */}
          <div className="flex flex-wrap gap-4 justify-center">
            {dataSource.images &&
              dataSource.images.map((image, index) => (
                <motion.img
                  key={index}
                  src={image.image_url}
                  alt={`Project Image ${index + 1}`}
                  className={`w-24 h-24 object-cover rounded-lg cursor-pointer shadow-md transition-all duration-300 ${
                    selectedImage === image.image_url
                      ? "ring-4 ring-blue-500 scale-105"
                      : "ring-2 ring-gray-200"
                  }`}
                  whileHover={{ scale: 1.1 }}
                  onClick={() => setSelectedImage(image.image_url)}
                />
              ))}
          </div>
        </div>
      )}

      {/* تفاصيل المشروع - مقسمة إلى بطاقات */}
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-6 mb-12">
        {/* عرض تفاصيل المشروع */}
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
          {t("project_area")}
          </h2>
          <p className="text-lg text-gray-900">{dataSource.area} {t("square_meter")}  </p>
        </motion.div>

        <motion.div
          className="bg-white rounded-lg shadow-lg p-6"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
          {t("completion_duration")}
          </h2>
          <p className="text-lg text-gray-900">
            {dataSource.completion_duration} {t("day")}
          </p>
        </motion.div>

        <motion.div
          className="bg-white rounded-lg shadow-lg p-6"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4"> {t("city")}  </h2>
          <p className="text-lg text-gray-900">{dataSource.governorate}</p>
        </motion.div>

        <motion.div
          className="bg-white rounded-lg shadow-lg p-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
          {t("number_of_workers")}
          </h2>
          <p className="text-lg text-gray-900">
            {dataSource.number_of_workers}
          </p>
        </motion.div>
      </div>

      {/* نافذة منبثقة لعرض الصورة بحجم أكبر */}
      <Modal open={openModal} onClose={() => setOpenModal(false)} center>
        <img
          src={selectedImage}
          alt="Expanded Project Image"
          className="w-full h-full object-cover"
        />
      </Modal>
    </div>
  );
};

export default ProductDetailByIdPage;
