import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"; // استيراد عناصر التوجيه
import HeroSection from "./components/HeroSection/HeroSection.jsx";
import StatisticCard from "./components/StatisticCard/StatisticCard.jsx";
import ProjectDetailSection from "./components/ProjectDetailSection/ProjectDetailSection.jsx";
import WatchSection from "./components/WatchSection/WatchSection.jsx";
import WatchSection1 from "./components/WatchSection1/WatchSection1.jsx";
import ProjectSlider from "./components/ProjectSlider/ProjectSlider.jsx";
import img1 from "./assets/img/img1.png";
import AboutUs from "./components/AboutUs/AboutUs.jsx";
import SalsublashScreen from "./components/SalsublashScreen/SalsublashScreen.jsx";
import { fetchDataProjects } from "./api/projectsApi/index.js";
import { fetchDataCategories } from "./api/ProductsApi/index.js";
import ContactAs from "./components/ContactAs/ContactAs.jsx";
import TabBar from "./components/Header/Header.jsx";
import Navbar from "./components/Header/Header2.jsx";
import ProjectDetailPage from "./page/ProjectPage/index.jsx";
import ProductDetailByIdPage from "./page/ProjectPage/projectById.jsx";
import { useTranslation } from "react-i18next";

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [headerBg, setHeaderBg] = useState("bg-white");
  const [showContent, setShowContent] = useState(false);
  const [dataSourceProject, setDataSourceProject] = useState([]);
  const [dataSourceSlider, setDataSourceSlider] = useState([]);
  const [dataSourceProductsr, setDataSourceProductsr] = useState([]);
  const fetchData = async () => {
    try {
      const data = await fetchDataProjects({
        paginate: 2,
        page: 1,
        search: "",
      });
      const data2 = await fetchDataProjects({
        paginate: 25,
        page: 1,
        search: "",
      });
      const DataProducts = await fetchDataCategories({
        paginate: 25,
        page: 1,
        search: "",
      });

      setDataSourceProject(data);
      setDataSourceSlider(data2.data);
      setDataSourceProductsr(DataProducts.data);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  useEffect(() => {
    setShowContent(true);
    const timer = setTimeout(() => {
      setShowContent(false);
    }, 4000);

    const observerTimer = setTimeout(() => {
      const targetElements = document.querySelectorAll(
        ".watch-section, .watch-section1"
      );

      const observer = new IntersectionObserver(
        (entries) => {
          let isSection1Visible = false;
          let isSectionVisible = false;

          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.target.classList.contains("watch-section1")) {
                isSection1Visible = true;
              } else if (entry.target.classList.contains("watch-section")) {
                isSectionVisible = true;
              }
            }
          });

          if (isSection1Visible) {
            setHeaderBg("bg-transparent text-white");
          } else if (isSectionVisible) {
            setHeaderBg("bg-[#202225] text-white");
          } else {
            setHeaderBg("bg-white text-gray-900");
          }
        },
        {
          threshold: 0.5,
        }
      );

      targetElements.forEach((targetElement) => {
        if (targetElement) {
          observer.observe(targetElement);
        }
      });

      return () => {
        targetElements.forEach((targetElement) => {
          if (targetElement) {
            observer.unobserve(targetElement);
          }
        });
      };
    }, 4100); // تأخير تنفيذ المراقبة حتى بعد إزالة SplashScreen

    return () => {
      clearTimeout(timer);
      clearTimeout(observerTimer);
    };
  }, []);
  useEffect(() => {
    let isStart = true;
    if (isStart) {
      fetchData();
    }
    return () => (isStart = false);
  }, []);
  const { t, i18n } = useTranslation();

  return (
    <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <div className="App overflow-hidden">
            {showContent ? (
              <SalsublashScreen />
            ) : (
              <>
                <TabBar
                  mobileMenuOpen={mobileMenuOpen}
                  setMobileMenuOpen={setMobileMenuOpen}
                  headerBg={headerBg}
                />

                <HeroSection
                  imgSrc={img1}
                  title="شركة اكساء العراق"
                  subtitle="لواجهات اكثر امان"
                />

                <ProjectDetailSection dataSource={dataSourceProject} />

                <div id="about">
                  <AboutUs />
                </div>

                <div id="projects" className="py-10 myClass2 mx-2 md:mx-10 h-[100vh]">
                  <ProjectSlider dataSource={dataSourceSlider} />
                </div>

                <div className="myClass2 watch-section h-[100vh] bg-[#202225] pt-20">
                  <WatchSection dataSource={dataSourceProductsr} />
                </div>

                <WatchSection1
                  title= {t("iron_product")}
                  description="Several countries witness its production..."
                  title2={t("glass_product")}
                  description2="Several countries witness its production..."
                />

                <div id="contact">
                  <ContactAs />
                </div>
              </>
            )}
          </div>
        }
      />
      <Route
        path="/project"
        element={
          <>
          <Navbar
                  mobileMenuOpen={mobileMenuOpen}
                  setMobileMenuOpen={setMobileMenuOpen}
                  headerBg={headerBg}
                />
              <ProjectDetailPage />

          </>
        }
      />
      <Route
          path="/project/:id"
          element={
            <>
            
            <Navbar
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              headerBg={headerBg}
            />
            <ProductDetailByIdPage />
            </>
          }
        />
    </Routes>
  </BrowserRouter>

  );
}

export default App;

