import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fetchDataProjects } from "../../api/projectsApi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectDetailPage = () => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: true, // عدم إعادة التحريك عند الخروج والدخول مرة أخرى
    threshold: 0.2,
  });
  const { t, i18n } = useTranslation();

  // حالات لتخزين البيانات، الصفحة الحالية، العدد الكلي، وحالة التحميل
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); // حالة التحميل

  // جلب البيانات من الـ API
  const fetchData = async (page) => {
    setLoading(true); // البدء بالتحميل
    try {
      const data = await fetchDataProjects({
        paginate: 25, // عدد العناصر في كل صفحة
        page: page,
        search: "",
      });
      setDataSource(data.data);
      setTotalPages(data.totalPages || 1); // تعيين العدد الإجمالي للصفحات
      setLoading(false); // إيقاف التحميل
    } catch (error) {
      console.log("Validate Failed:", error);
      setLoading(false); // إيقاف التحميل عند حدوث خطأ
    }
  };

  useEffect(() => {
    fetchData(currentPage); // جلب البيانات عند تغيير الصفحة
  }, [currentPage]);

  // التنقل بين الصفحات
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="min-h-screen flex flex-col items-center md:mt-24 justify-center p-4 gap-6 mb-20">
      {/* عرض الكروت */}
      <div
        ref={statsRef}
        className="w-full flex flex-wrap items-center justify-center gap-8"
      >
        {loading ? (
          // عرض مؤشر تحميل عند جلب البيانات
          <div className="flex items-center justify-center h-[50vh]">
            <div className="loader border-t-4 border-b-4 border-blue-500 w-12 h-12 rounded-full animate-spin"></div>
           </div>
        ) : (
          dataSource.map((project, index) => (
            <motion.div
              key={index}
              className="relative w-full max-w-xs md:max-w-md h-[40vh] md:h-[50vh] shadow-lg rounded-lg overflow-hidden bg-white hover:shadow-xl transition-shadow duration-300" // تحسين التصميم
              initial={{ opacity: 0, y: 30 }} // بدء الحركة من الأسفل
              animate={{ opacity: 1, y: 0 }} // الانتقال إلى الوضع الطبيعي
              transition={{ duration: 0.5, delay: index * 0.1 }} // تقليل مدة الحركة
            >
              {/* الصورة */}
              <Link to={project.id} >
              
            
              <img
                className="w-full h-1/2 object-cover"
                src={project.images[0]?.image_url || "default-image.jpg"}
                alt={`Project Image ${index + 1}`}
              />
                </Link>

              {/* النص المعروض فوق الصورة */}
              <div className="p-4">
                <h3 className="text-xl font-semibold text-gray-800 mb-2 truncate">
                  { i18n.language === "ar" ?  project.name_ar : project.name_en}
                </h3>
                <p className="text-sm text-gray-600 line-clamp-3 h-16 overflow-hidden">
                  { i18n.language === "ar" ?  project.description_ar :  project.description_en}
                </p>

                {/* باقي التفاصيل */}
                <div className="flex items-center justify-between mt-4">
                  <div className="text-center">
                    <p className="text-xl font-bold text-blue-500">
                      {project.area}
                    </p>
                    <p className="text-xs text-gray-500">{t("project_area")} </p>
                  </div>
                  <div className="text-center">
                    <p className="text-xl font-bold text-blue-500">
                      {project.completion_duration} {t("day")}
                    </p>
                    <p className="text-xs text-gray-500"> {t("completion_duration")} </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))
      
        )}
      </div>

      {/* التحكم بالصفحات */}
      {!loading && (
        <div className="flex items-center justify-center mt-8 gap-4">
          <button
            onClick={goToPreviousPage}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={currentPage === 1}
          >
            {t("previous")}
          </button>
          <p className="text-lg font-bold text-gray-700">
          {t("page")} {currentPage} {t("of")} {totalPages}
          </p>
          <button
            onClick={goToNextPage}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={currentPage === totalPages}
          >
              {t("next")}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailPage;
