import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlineProject,
  AiOutlinePhone,
} from "react-icons/ai";
import logo from "../../assets/img/logo.png";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa"; // إضافة أيقونة عالمية كتبديل اللغة

const Navbar = ({ headerBg }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* Navbar for large screens */}
      <header
        className={`hidden lg:block w-full shadow-md ${headerBg} fixed top-0 z-50`}
      >
        <nav
          aria-label="Global"
          className="mx-auto flex w-full items-center justify-between p-4 lg:px-4"
        >
          <div className="flex lg:flex-1 justify-start  items-center">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="-m-1.5 p-1.5"
            >
              <div className="bg-black p-2 rounded-lg ml-10">
                <img alt="Company Logo" src={logo} className="h-8 w-auto" />
              </div>
            </Link>
            {i18n.language === "ar" && (
              <button
                className="flex items-center px-2 py-1 bg-gray-200 hover:bg-gray-300 text-sm font-medium rounded-full focus:outline-none transition-colors duration-300"
                onClick={() => i18n.changeLanguage("en")}
                aria-label="Switch to English"
              >
                <span className="mr-1">EN</span>
                <FaGlobe className="h-5 w-5" />
              </button>
            )}
            {i18n.language === "en" && (
              <button
                className="flex items-center px-2 py-1 bg-gray-200 hover:bg-gray-300 text-sm font-medium rounded-full focus:outline-none transition-colors duration-300"
                onClick={() => i18n.changeLanguage("ar")}
                aria-label="Switch to Arabic"
              >
                <FaGlobe className="h-5 w-5" />
                <span className="ml-1">AR</span>
              </button>
            )}
          </div>
          <div className="flex space-x-6 space-x-reverse">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="text-sm font-semibold leading-6 cursor-pointer"
            >
              {t("home")}
            </Link>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
              className="text-sm font-semibold leading-6 cursor-pointer"
            >
              {t("About-us")}
            </Link>
            <Link
              activeClass="active"
              to="projects"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
              className="text-sm font-semibold leading-6 cursor-pointer"
            >
              {t("Project")}
            </Link>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
              className="text-sm font-semibold leading-6 cursor-pointer"
            >
              {t("Contact-us")}
            </Link>
          </div>
        </nav>
      </header>

      {/* Tab Bar for small screens */}
      <header className="lg:hidden fixed bottom-0 left-0 w-full z-50 bg-white shadow-md">
        <nav className="flex justify-around items-center py-2">
          {i18n.language === "ar" && (
            <button
              className="flex items-center px-2 py-1 bg-gray-200 hover:bg-gray-300 text-sm font-medium rounded-full focus:outline-none transition-colors duration-300"
              onClick={() => i18n.changeLanguage("en")}
              aria-label="Switch to English"
            >
              <span className="mr-1">EN</span>
              <FaGlobe className="h-5 w-5" />
            </button>
          )}
          {i18n.language === "en" && (
            <button
              className="flex items-center px-2 py-1 bg-gray-200 hover:bg-gray-300 text-sm font-medium rounded-full focus:outline-none transition-colors duration-300"
              onClick={() => i18n.changeLanguage("ar")}
              aria-label="Switch to Arabic"
            >
              <FaGlobe className="h-5 w-5" />
              <span className="ml-1">AR</span>
            </button>
          )}

          <Link
            activeClass="active-tab"
            to="home"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="text-center flex flex-col items-center cursor-pointer hover:text-blue-500 transition-colors duration-300"
          >
            <AiOutlineHome className="h-6 w-6" />
            <span className="text-xs">{t("home")}</span>
          </Link>
          <Link
            activeClass="active-tab"
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="text-center flex flex-col items-center cursor-pointer hover:text-blue-500 transition-colors duration-300"
          >
            <AiOutlineInfoCircle className="h-6 w-6" />
            <span className="text-xs">{t("About-us")}</span>
          </Link>
          <Link
            activeClass="active-tab"
            to="projects"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="text-center flex flex-col items-center cursor-pointer hover:text-blue-500 transition-colors duration-300"
          >
            <AiOutlineProject className="h-6 w-6" />
            <span className="text-xs">{t("Project")}</span>
          </Link>
          <Link
            activeClass="active-tab"
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="text-center flex flex-col items-center cursor-pointer hover:text-blue-500 transition-colors duration-300"
          >
            <AiOutlinePhone className="h-6 w-6" />
            <span className="text-xs">{t("Contact-us")}</span>
          </Link>
        </nav>
      </header>

      {/* Mobile Menu for small screens */}
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <div className="bg-black p-2 rounded-lg">
                <img alt="Company Logo" src={logo} className="h-8 w-auto" />
              </div>
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  activeClass="active-tab"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  activeClass="active-tab"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About us
                </Link>
                <Link
                  activeClass="active-tab"
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Projects
                </Link>
                <Link
                  activeClass="active-tab"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default Navbar;
