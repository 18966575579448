import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Link } from "react-scroll";
import {
  fetchDataProducts,
  fetchDataProductsById,
} from "../../api/ProductsApi";
import { useTranslation } from "react-i18next";

const WatchSection = ({ dataSource }) => {
  const { t, i18n } = useTranslation();

  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const [activeContent, setActiveContent] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [productDetails, setProductDetails] = useState(null); // لتخزين تفاصيل المنتج المحدد
  const [isTransitioning, setIsTransitioning] = useState(false); // حالة لتفعيل الرسوم المتحركة عند تغيير الفئة
  const [isImageTransitioning, setIsImageTransitioning] = useState(false); // حالة لتفعيل الرسوم المتحركة عند تغيير الصورة

  const handleCategorieChange = async (index, id) => {
    setIsTransitioning(true); // تفعيل الرسوم المتحركة عند تغيير الفئة
    setActiveContent(index);
    setCategoryId(id);

    // جلب المنتجات الخاصة بالفئة
    const data = await fetchDataProduct(id);
    setSelectedProduct(data.data);

    // تعيين المنتج والصورة الأولى كافتراضيين
    if (data.data.length > 0) {
      fetchProductsById(data.data[0].id, 0);
    }

    setTimeout(() => {
      setIsTransitioning(false); // إلغاء حالة الانتقال بعد انتهاء التغيير
    }, 500); // مدة الرسوم المتحركة
  };

  const fetchDataProduct = async (categoryId) => {
    try {
      const data = await fetchDataProducts({
        paginate: 25,
        page: 1,
        search: "",
        categoryId: categoryId,
      });
      return data;
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const fetchProductsById = async (id, index) => {
    setIsImageTransitioning(true); // تفعيل الرسوم المتحركة عند تغيير الصورة
    try {
      const data = await fetchDataProductsById({ id });
      setProductDetails(data.data);
      setActiveImageIndex(index);

      setTimeout(() => {
        setIsImageTransitioning(false); // إلغاء حالة الانتقال بعد انتهاء التغيير
      }, 500); // مدة الرسوم المتحركة
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (dataSource.length > 0) {
        // تحديد الفئة الأولى افتراضيًا عند بدء التشغيل
        const initialCategoryId = dataSource[0].id;
        setCategoryId(initialCategoryId);

        // جلب المنتجات الخاصة بالفئة الأولى
        const data = await fetchDataProduct(initialCategoryId);
        setSelectedProduct(data.data);

        // تعيين المنتج والصورة الأولى كافتراضيين
        if (data.data.length > 0) {
          fetchProductsById(data.data[0].id, 0);
        }
      }
    };
    getData();
  }, [dataSource]);

  return (
    <div className="bg-[#202225] text-white h-[80vh] p-4">
      <div
        className="md:pt-16 bg-[#202225] -mt-24 h-full md:mt-0 text-white md:mx-20 p-4 md:p-8"
        ref={statsRef}
        dir="rtl"
      >
        <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 h-full">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={statsInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="flex flex-col justify-between text-center md:text-right"
          >
            <div className="flex flex-col justify-start items-start">
              <motion.h2
                initial={{ opacity: 0, y: -50 }}
                animate={statsInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: "easeOut" }}
                className="text-xl md:text-3xl font-bold mb-4 md:mb-6 "
              >
                {t("products")}
              </motion.h2>
              <hr className="flex flex-col justify-start items-start w-12 md:w-32 border-b-2 border-y-white mb-4 md:mb-6 md:mx-0" />

              <motion.ul
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
                className=" md:right-10 lg:right-20 list-disc list-inside text-sm md:text-base lg:text-lg"
              >
                {dataSource.map((data, index) => (
                  <Link
                    key={index}
                    activeClass="active"
                    to={data.name_ar}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className="cursor-pointer"
                  >
                    <li
                      className={`mb-2 cursor-pointer transition-colors ${
                        activeContent === index
                          ? "text-white font-bold"
                          : "text-gray-500 hover:text-yellow-400"
                      } hover:animate__animated hover:animate__pulse`}
                      onClick={() => handleCategorieChange(index, data.id)}
                    >
                      { i18n.language === "ar" ?  data.name_ar : data.name_en}
                    </li>
                  </Link>
                ))}
              </motion.ul>
              {selectedProduct && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={statsInView && !isTransitioning ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 1, ease: "easeOut" }}
                  className="mt-4 md:mt-8"
                >
                  <p className="text-sm md:text-lg leading-relaxed text-gray-300 break-words whitespace-normal">
                    { i18n.language === "ar" ? selectedProduct[activeImageIndex]?.description_ar : selectedProduct[activeImageIndex]?.description_en ||
                      "الوصف غير معروف"}
                  </p>
                </motion.div>
              )}
            </div>
          </motion.div>

          {selectedProduct && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={statsInView && !isTransitioning ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="flex col-span-2  flex-col w-full justify-end items-center mt-8 md:mt-0"
            >
              <div className="flex justify-center  h-[100px] md:justify-end items-start w-full gap-2 md:gap-4 mb-4 md:mb-2">
                {selectedProduct &&
                  selectedProduct.map((product, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={statsInView && !isTransitioning ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                      onClick={() => fetchProductsById(product.id, index)}
                      transition={{
                        duration: 0.8,
                        delay: 0.5 + index * 0.1,
                        ease: "easeOut",
                      }}
                      className={`h-14 w-14 md:h-20 md:w-20 cursor-pointer rounded-lg overflow-hidden 
                        ${activeImageIndex === index ? "border-4 border-yellow-500" : "border border-transparent"}
                      `}
                    >
                      <img
                        className="h-full w-full rounded-lg object-cover shadow-lg"
                        src={product?.images[0]?.image_url}
                        alt={`Product ${index}`}
                      />
                    </motion.div>
                  ))}
              </div>
              {selectedProduct.length > 0 && (
                <div className="relative h-full  w-full">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{ clickable: true }}
                    className="relative p-2 rounded-xl flex justify-center md:justify-end items-center h-[180px] md:h-[500px] overflow-hidden"
                  >
                    {selectedProduct[activeImageIndex]?.images?.map(
                      (image, idx) => (
                        <SwiperSlide key={idx}>
                          <motion.img
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={statsInView && !isImageTransitioning ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                            transition={{
                              duration: 0.8,
                              delay: 1 + idx * 0.2,
                              ease: "easeOut",
                            }}
                            className="w-full h-full rounded-lg object-cover shadow-xl"
                            src={image.image_url}
                            alt={`Slide ${idx}`}
                          />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>

                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={statsInView && !isImageTransitioning ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                    transition={{ duration: 1, delay: 1.2, ease: "easeOut" }}
                    className="p-4 md:p-6 w-full flex flex-col md:flex-row justify-between text-sm md:text-lg mt-4 md:mt-1 rounded-lg"
                  >
                    <span className="text-gray-400 mb-2 md:mb-0">
                      <strong> {t("product_name")} :  </strong>{" "}
                      { i18n.language === "ar" ?  selectedProduct[activeImageIndex]?.name_ar : selectedProduct[activeImageIndex]?.name_en||
                        "لا يوجد اسم"}
                    </span>
                    <span className="text-gray-400 mb-2 md:mb-0">
                      <strong>  {t("size")}  :</strong>{" "}
                      {selectedProduct[activeImageIndex]?.height &&
                      selectedProduct[activeImageIndex]?.length
                        ? `${selectedProduct[activeImageIndex].height} * ${selectedProduct[activeImageIndex].length}`
                        : "الحجم غير معروف"}
                    </span>
                    <span className="text-gray-400">
                      <strong>  {t("depth")} :</strong>{" "}
                      {selectedProduct[activeImageIndex]?.depth ||
                        "العمق غير معروف"}
                    </span>
                  </motion.div>
                </div>
              )}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchSection;
