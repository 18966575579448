import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import logo from "../../assets/img/logo.png"; // تأكد من وضع مسار الشعار الصحيح
import exa from "../../assets/img/exa.png";

const SalsublashScreen = () => {
  const [showText, setShowText] = useState(false); // لإظهار النص
  const [moveTextDown, setMoveTextDown] = useState(false); // لتحريك النص للأسفل
  const [showLogo, setShowLogo] = useState(false); // لإظهار الشعار
  const [hideScreen, setHideScreen] = useState(false); // لإخفاء الشاشة بعد ظهور الشعار

  useEffect(() => {
    // إظهار النص أولاً
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500); // النص يظهر بعد نصف ثانية

    // تحريك النص للأسفل بعد ظهوره
    const moveTextTimer = setTimeout(() => {
      setMoveTextDown(true);
    }, 2000); // يبدأ النص بالنزول بعد 1.5 ثانية

    // إظهار الشعار بعد النص
    const logoTimer = setTimeout(() => {
      setShowLogo(true);
    }, 2000); // الشعار يظهر بعد تحرك النص بـ 1 ثانية

    // إخفاء الشاشة بالكامل بعد ثانية من ظهور الشعار
    const hideScreenTimer = setTimeout(() => {
      setHideScreen(true);
    }, 5000); // إخفاء الشاشة بعد ثانية من ظهور الشعار

    return () => {
      clearTimeout(textTimer);
      clearTimeout(moveTextTimer);
      clearTimeout(logoTimer);
      clearTimeout(hideScreenTimer);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 1 }} // الشاشة تبدأ مرئية
      animate={{ opacity: hideScreen ? 0 : 1 }} // إخفاء الشاشة بعد مرور الوقت
      transition={{ duration: 1 }} // مدة الاختفاء 1 ثانية
      className="h-screen w-screen bg-gradient-to-b from-[#1E1E1E] to-[#343434] flex justify-center items-center relative"
    >
      {/* النص الأول يظهر من اليمين */}
      {showText && (
        <motion.div
          initial={{ opacity: 0, x: 300 }} // النص يظهر من اليمين
          animate={{ opacity: 1, x: 0, y: moveTextDown ? 30 : 0 }} // يتحرك النص إلى المنتصف
          transition={{ duration: 1, ease: "easeInOut", bounce: 0.3 }} // استخدام تأثير bounce
          className="text-center"
        >
          <motion.p
            initial={{ opacity: 0, x: 300 }} // النص الأول يظهر من اليمين
            animate={{ opacity: 1, x: 0 }} // يتحرك إلى المنتصف
            transition={{ duration: 1, ease: "easeInOut" }}
            className="font-extrabold text-[40px] sm:text-[30px] lg:text-[50px] tracking-wide text-white"
          >
            شركة اكساء العراق
          </motion.p>

          {/* النص الثاني يظهر من اليسار */}
          <motion.p
            initial={{ opacity: 0, x: "-100vw" }} // يبدأ النص من خارج الشاشة تماماً على اليسار
            animate={{ opacity: 1, x: 0 }} // يتحرك إلى الموضع الطبيعي في المنتصف
            transition={{ duration: 1, ease: "easeInOut" }} // مدة الانتقال والحركة
            className="text-[20px] sm:text-[18px] lg:text-[30px] mx-10 font-serif text-gray-300"
          >
            <img
            src={exa}
            alt="logo"
            className="w-full lg:w-[800px] "
          />          </motion.p>
        </motion.div>
      )}

      {/* الشعار يظهر بعد حركة النص للأسفل */}
      {showLogo && (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: -150 }} // الشعار يظهر فوق النص
          transition={{ duration: 1 }}
          className="absolute"
        >
          <img
            src={logo}
            alt="logo"
            className="w-[150px] h-[200px] lg:w-[200px] lg:h-[250px]"
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default SalsublashScreen;
